function genVideoAdv(advertise) {
  const advList = advertise.vecAdvPositonResp[0].vecSecureAdvertise;
  const adv = {};

  if (advList.length) {
    const oTmpAdv = advList[0].advertise;
    const { picWidth } = oTmpAdv.displayInfo;
    const { picHeight } = oTmpAdv.displayInfo;
    if (picWidth > picHeight) { // 横屏
      adv.direct = 0;
    } else { // 竖屏
      adv.direct = 1;
    }
    adv.jumpUrl = oTmpAdv.content.jumpUrl;
    adv.isDownloadAdv = !!oTmpAdv.content.appDownloadUrl;
    if (adv.isDownloadAdv) {
      adv.downloadLink = oTmpAdv.content.appDownloadUrl;
      adv.pkgName = oTmpAdv.content.packageName;
      adv.advTitle = oTmpAdv.displayInfo.text2;
      adv.advIcon = oTmpAdv.displayInfo.imgUrl2;
      adv.advDesc = oTmpAdv.displayInfo.text1;
    }
    // 视频下方广告页面
    const tmpUrl = adv.jumpUrl || oTmpAdv.content.customedUrl;
    if (tmpUrl && tmpUrl.indexOf('http') > -1) {
      adv.advUrl = tmpUrl.replace(/http:/gi, 'https:');
    }

    // 如果找到广告
    adv.videoUrl = oTmpAdv.displayInfo.videoUrl.replace(/https/gi, 'http');

    adv.videoPoster = oTmpAdv.displayInfo.imgUrl1;
    adv.statCtx = oTmpAdv.context;
    adv.eventTracking = oTmpAdv.content.eventTracking;
    adv.externalResourceExtraData = oTmpAdv.content.externalResourceExtraData;
  }
  return adv;
}

function genBannerAdv(advertise) {
  const advList = advertise.vecAdvPositonResp[0].vecSecureAdvertise;
  const adv = {};

  if (advList.length) {
    const oTmpAdv = advList[0].advertise;
    const { picWidth } = oTmpAdv.displayInfo;
    const { picHeight } = oTmpAdv.displayInfo;
    if (picWidth > picHeight) { // 横屏
      adv.direct = 0;
    } else { // 竖屏
      adv.direct = 1;
    }
    adv.desc = oTmpAdv.displayInfo.text1;
    adv.title = oTmpAdv.displayInfo.text2;
    adv.btnText = oTmpAdv.displayInfo.text3;
    adv.banner = oTmpAdv.displayInfo.imgUrl1;
    adv.icon = oTmpAdv.displayInfo.imgUrl2;
    adv.jumpUrl = oTmpAdv.content.jumpUrl;
    adv.isDownloadAdv = oTmpAdv.content.contentType === 2;
    adv.eventTracking = oTmpAdv.content.eventTracking;
    adv.externalResourceExtraData = oTmpAdv.content.externalResourceExtraData;

    // 视频下方广告页面
    const tmpUrl = adv.jumpUrl || oTmpAdv.content.customedUrl;
    if (tmpUrl && tmpUrl.indexOf('http') > -1) {
      adv.advUrl = tmpUrl.replace(/http:/gi, 'https:');
    }

    adv.statCtx = oTmpAdv.context;
  }
  return adv;
}

function genCouponAdv(advertise) {
  let adv = {};
  const swList = advertise.vecAdvPositonResp[0].vecSecureAdvertise;
  if (swList.length) {
    const { content } = swList[0].advertise;
    const displayContent = swList[0].advertise.displayInfo;
    const { context } = swList[0].advertise;

    adv = {
      img: displayContent.imgUrl2,
      title: displayContent.text1,
      appName: displayContent.text2,
      btnText: displayContent.test3,
      jumpUrl: content.jumpUrl,
      appDownloadUrl: content.appDownloadUrl,
      statCtx: context,
    };
  }
  return adv;
}

function genDownloadAdv(advertise) {
  let adv = {};
  const swList = advertise.vecAdvPositonResp[0].vecSecureAdvertise;
  if (swList.length) {
    const downloadContent = swList[0].advertise.content;
    const displayContent = swList[0].advertise.displayInfo;
    const { context } = swList[0].advertise;

    adv = {
      pkgName: downloadContent.packageName,
      advTitle: displayContent.text2,
      advIcon: displayContent.imgUrl2,
      downloadLink: downloadContent.appDownloadUrl,
      advDesc: displayContent.text1,
      statCtx: context,
    };
  }
  return adv;
}

function genAdvertise(advertise) {
  const adv = advertise.vecAdvPositonResp[0].vecSecureAdvertise;
  return adv;
}

function genImageAdv(advertise) {
  const advList = advertise.vecAdvPositonResp[0].vecSecureAdvertise;
  const adv = {};

  if (advList.length) {
    const oTmpAdv = advList[0].advertise;
    adv.desc = oTmpAdv.displayInfo.text1;
    adv.title = oTmpAdv.displayInfo.text2;
    adv.btnText = oTmpAdv.displayInfo.text3;
    adv.banner = oTmpAdv.displayInfo.imgUrl1;
    adv.icon = oTmpAdv.displayInfo.imgUrl2;
    adv.appDownloadUrl = oTmpAdv.content.appDownloadUrl;
    adv.jumpUrl = oTmpAdv.content.jumpUrl;
    adv.customedUrl = oTmpAdv.content.customedUrl;
    adv.pkgName = oTmpAdv.content.packageName;
    adv.statCtx = oTmpAdv.context;
    adv.eventTracking = oTmpAdv.content.eventTracking;
    adv.externalResourceExtraData = oTmpAdv.content.externalResourceExtraData;
  }
  return adv;
}

export {
  genVideoAdv,
  genBannerAdv,
  genCouponAdv,
  genDownloadAdv,
  genAdvertise,
  genImageAdv,
};
