import Ajax from './request';
import { genVideoAdv, genBannerAdv, genCouponAdv, genDownloadAdv, genAdvertise, genImageAdv } from './adv';

const _ua = navigator.userAgent.toLowerCase();

class CoralAdv {
  constructor(options) {
    this.options = options || {};
    this.appId = options.appId;
    this.advTarget = options.advParams ? options.advParams.target : 1;
    this.userParams = options.userParams;
    this.userParams.login_type = _ua.indexOf('micromessenger') > -1 ? '1' : _ua.match(/QQ/i) === 'qq' ? '2' : '';
    this.guidInfo = options.guidInfo;
    this.guid = this.guidInfo.guid || '';
    this.imei = this.guidInfo.imei || '';
    delete this.guidInfo.guid;
    this.advConfList = {};
  }

  // 必须要在这里回调
  async ready(cb, errCb) {
    console.log(1030, this.options);
    if (cb) {
      if (document.readyState !== 'loading') {
        this.doInit(cb, errCb);
      } else if (window.addEventListener) {
        // DOMContentLoaded - 所有DOM解析完会触发整个事件 不需要等到样式表、图片等加载完。
        window.addEventListener('DOMContentLoaded', () => {
          this.doInit(cb, errCb);
        });
      } else {
        window.attachEvent('onreadystatechange', () => {
          if (document.readyState !== 'loading') ;
          this.doInit(cb, errCb);
        });
      }
    }
  }

  async doInit(cb, errCb) {
    const {
      appId,
      timestamp,
      nonceStr,
      signature,
    } = this.options;

    if (!appId) {
      errCb('empty appid');
    }
    if (!timestamp) {
      errCb('empty timestamp');
    }

    if (!signature) {
      errCb('empty signature');
    }
    if (!nonceStr) {
      errCb('empty nonceStr');
    }
    this.ajax = new Ajax({
      header: {
        'coral-sign-params': `nonce=${nonceStr};signature=${signature};ts=${timestamp};appid=${appId}`,
        'doc-url': window.location.href
      },
    });
    await this.init(cb, errCb);
  }

  init(cb, errCb) {
    console.log('init');
    return new Promise(async () => {
      this.ajax.get('extfe/getProductAdvConfList', { appid: this.appId, target: this.advTarget }).then((res) => {
        // 按照广告类型分组
        if (res.ret === 0) {
          const advConfList = res.data;
          for (let i = 0; i < advConfList.length; i++) {
            this.advConfList[advConfList[i].adv_type] = advConfList[i].adv_id;
          }
          cb();
        } else {
          errCb(res.ret);
        }
      })
        .catch((err) => {
          errCb('拉取广告配置失败');
          console.log('getProductAdvConfList err', err);
        });
    });
  }

  async getAdvertise(type) {
    const params = { guidinfo: this.guidInfo };
    Object.assign(
      params,
      { adv_id: this.advConfList[type] },
      this.userParams,
      { guid: this.guid, imei: this.imei },
    );
    const res = await this.ajax.post(`extfe/fetchQianTuAdvEasyV2?appid=${this.appId}`, params);
    console.log('getAdvertise', type, res);
    let adv = {};
    if (res.ret === 0) {
      switch (type) {
        case 1:
          adv = genVideoAdv(res.data);
          break;
        case 2:
          adv = genBannerAdv(res.data);
          break;
        case 3:
          adv = genDownloadAdv(res.data);
          break;
        case 4:
          adv = genCouponAdv(res.data);
          break;
        case 15:
        case 16:
        case 17:
          adv = genAdvertise(res.data);
          break;
        case 18:
          adv = genImageAdv(res.data);
          break;
      }
    }
    // 上报
    if (adv.statCtx && adv.statCtx.length) {
      this.statAdvertise(type, 2, adv.statCtx);
    }
    return adv;
  }

  statAdvertise(type, phase, context) {
    const params = {};
    Object.assign(
      params, { adv_id: this.advConfList[type], phase, context },
      this.userParams, { guid: this.guid, imei: this.imei },
    );
    this.ajax.post(`extfe/statQianTuAdvEasyV2?appid=${this.appId}`, params).then((res) => {
      console.log('statQianTuAdvEasyV2', type, res);
    })
      .catch((err) => {
        console.log('statQianTuAdvEasyV2 err', type, err);
      });
  }
}

export default CoralAdv;
